<template>
  <div style="width: 100%">
    <div
      style="
        margin: 0 auto;
        height: 420px;
        max-width: 1600px;
        margin-top: -30px;
      "
    >
      <slider ref="slider" :options="options">
        <template :slot-scope="coverflow">
          <slideritem
            v-for="(slide, index) in slides"
            :pageLength="slides.length"
            :index="index"
            :key="index"
          >
            <card
              :title="slide.title"
              :category="slide.category"
              :file="{}"
              :id="slide.id"
              :url="slide.url"
              @open="openPost($event)"
              :height="height"
            />
          </slideritem>
        </template>
      </slider>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";

export default {
  name: "Carousel",
  data() {
    return {
      //Slider configuration [obj]
      options: {
        effect: "coverflow",
        autoplay: 4000,
        currentPage: 0,
        thresholdDistance: 100,
        thresholdTime: 300,
        deviation: this.deviation(),
        slidesToScroll: 1,
        loop: true,
      },
    };
  },
  components: {
    Card: () => import("./Card.vue"),
    slider,
    slideritem,
  },
  props: {
    slides: Array,
    height: Number,
  },
  methods: {
    openPost(id) {
      //  this.$router.push({ name: 'Post', params: { id: id, text:'Notícia', href:'/news', type:'news'} })
      this.$router.push({ path: `/post/news/${id}` });
    },
    deviation() {
      if (window.innerWidth <= 600) {
        return 200;
      } else {
        return 540;
      }
    },
  },
};
</script>

<style lang="scss">
.slider-item {
  width: 580px !important;

  .v-card .data-card {
    background: rgba(255, 255, 255, 0.304) !important;
    box-shadow: 0px 06px 08px rgba(0, 0, 0, 0.3) !important;
  }

  @media screen and (max-width: 600px) {
    width: 490px !important;
  }
}
.slider-active {
  .v-card .data-card {
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9)
    ) !important;
  }
}
.swiper-container-horizontal > .slider-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container-horizontal > * > .slider-pagination-bullet {
  height: 12px !important;
  width: 12px !important;
}
.swiper-container-vertical .slider-pagination-bullet-active,
.swiper-container-horizontal .slider-pagination-bullet-active {
  background: #334666 none repeat scroll 0 0 !important;
  opacity: 1 !important;
}
</style>
